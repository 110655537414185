/* eslint-disable jsx-a11y/control-has-associated-label */

import React from "react"

import { Layout, Seo, classes } from "../../layout"
import { FindOutMoreButton } from "../../components"

export default function IndexPage(props) {
  const { heading, paragraph } = classes

  return (
    <Layout path={props.path}>
      <Seo title="Trailguide Solutions" />

      <h1
        className={heading + " mx-auto mt-20 md:mt-40 max-w-4xl px-6 md:px-0"}
      >
        Trailguide solutions
      </h1>

      <div className="mx-auto max-w-4xl my-12 px-6 md:px-0">
        <p className={paragraph}>
          We truly believe that technology can enhance and enrich the outdoor
          experience for your guests and visitors. By using our solutions for
          digitalization and immediate accessibility you can offer your visitors
          a premium experience.
        </p>
      </div>

      <div className="mx-auto max-w-4xl my-12 px-6 md:px-0 mb-24">
        <FindOutMoreButton className="m-4" to="/solutions/destination">
          Solutions for tourist destinations
        </FindOutMoreButton>

        <FindOutMoreButton className="m-4" to="/solutions/resort">
          Solutions for resorts and similar
        </FindOutMoreButton>

        <FindOutMoreButton className="m-4" to="/solutions/bikeshop">
          Solutions for bike shops
        </FindOutMoreButton>

        <FindOutMoreButton className="m-4" to="/solutions/sykkelbutikk">
          Løsninger for sykkelbutikker
        </FindOutMoreButton>
      </div>
    </Layout>
  )
}
